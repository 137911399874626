import { IonButton, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup, IonRow, IonTitle } from '@ionic/react';
import './Instruccion.css';
import logo from '../assets/imgs/intro/logo.png'
import 'animate.css';
import { useHistory } from 'react-router';


const Instruccion: React.FC = () => {

  const history = useHistory();
  const continuar = () => {
    if (select == 0) {
      alert('Selecciona una opción para continuar')
    } else {
      if (select != 2) {
        alert('Lee nuevamente las instrucciones y selecciona la respuesta correcta')
      } else {
        history.push('/items/1')
      }
    }
  }

  let options = [1, 2, 3, 4, 5];
  let descp = ['Nunca', 'Casi Nunca', 'A veces', 'Casi siempre', 'Siempre'];
  let select: number = 0
  const getValue = (value: number) => {
    select = value
  }

  return (
    <IonPage>
      <IonContent fullscreen className='contentInstruccion'>
        <IonGrid className='gridInstruccion'>
          <IonRow className='rowInstruccion ion-justify-content-center'>
            <IonCol size='10' className='ion-justify-content-center ion-align-self-center ion-text-center'>
              <span>A continuación encontrarás diferentes afirmaciones. Queremos que nos
                cuentes <b>en una escala del 1 a 5</b> qué tan frecuentemente realizas las acciones presentadas.
                <br></br> <br></br>La escala corresponde a los siguientes valores</span>
            </IonCol>

            <IonCol size='11' className='ion-align-self-center ion-padding'>
              <IonRadioGroup value="" onIonChange={e => getValue(e.detail.value)}>
                <IonRow>

                  {
                    options.map((opt, index) =>
                      <IonCol key={opt} sizeMd='2.4' size='12' className='opc ion-no-padding'>
                        <IonRow className='opcRow'>
                          <IonCol size='12' className='opcCol ion-no-padding'>
                            <IonItem className="itemOpt">
                              <IonLabel className='labelOpt ion-text-center'>
                                <h2 className='headerOpt'>{options[index]}</h2>
                                <h2 className='bodyOpt'>{descp[index]}</h2>
                                <IonRadio id={String(opt)} className='radioSelect' value={opt}>{opt}</IonRadio>
                              </IonLabel>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    )
                  }

                </IonRow>
              </IonRadioGroup>
            </IonCol>

            <IonCol size='12' className='ion-margin-vertical ion-no-padding ion-text-center'>
              <span>Ahora responde <b>casi nunca</b> si entendiste la instrucción</span>
            </IonCol>

            <IonCol size='12' className='ion-no-padding ion-text-center'>
              <IonButton color='btncheck' onClick={continuar}>Iniciar prueba</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage >
  );
};

export default Instruccion;
