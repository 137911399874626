import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import './TyC.css';
import recurso1 from '../assets/imgs/tyc/Recurso1.png'
import recurso2 from '../assets/imgs/tyc/Recurso2.png'
import recurso3 from '../assets/imgs/tyc/Recurso3.png'
import recurso4 from '../assets/imgs/tyc/Recurso4.png'
import recurso5 from '../assets/imgs/tyc/Recurso5.png'

import 'animate.css';
import { useHistory } from 'react-router';
import React from 'react';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const TyC: React.FC = () => {

  const [slide, setSlide] = React.useState(0)
  const history = useHistory();
  const continuar = () => {
    history.push('/registro')
  }

  return (
    <IonPage>
      <IonContent fullscreen className='contentTyC'>
        <IonGrid className='gridTyC'>
          <IonRow className='rowTyC'>
            <IonCol size='12' className='colTyC ion-justify-content-center ion-align-self-center'>
              <Swiper
                className='swiperTyC'
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                onSlideChange={(data) => setSlide(data.activeIndex)}
              >
                <SwiperSlide className='swiperSlide'>
                  {slide == 0 ?
                    <IonGrid className='gridSwiperTyC'>
                      <IonRow>
                        <IonCol size="12" size-md='5.5' className='ion-align-self-center ion-padding'>
                          <img className='ion-padding ion-margin animate__animated animate__bounceIn' src={recurso2}></img>
                        </IonCol>
                        <IonCol size="12" size-md='6.5' className='ion-align-self-center ion-padding'>
                          <img className='ion-padding ion-margin animate__animated animate__bounceIn' src={recurso1}></img>
                        </IonCol>
                      </IonRow>
                    </IonGrid> : <div></div>}
                </SwiperSlide>
                <SwiperSlide>
                  {slide == 1 ?
                    <IonGrid className='gridSwiperTyC'>
                      <IonRow>
                        <IonCol size='12' sizeMd='5.5'>
                          <span>De conformidad con lo previsto en la ''Ley estatutaria 1581 de 2012'', y sus ''Decretos reglamentarios'' 1377 de 2013 y 1074 de 2015 capitulo 25, autorizo con consentimiento previo, expreso, informado, voluntario e inequívoco como Titular de mis datos a la CORPORACIÓN UNIFICADA NACIONAL DE EDUCACIÓN SUPERIOR - CUN para que realice el respectivo tratamiento de mis datos personales, respuestas de formulario, imágenes y/o vídeos, y en especial aquellos definidos como datos sensibles, para que sean incorporados en una base de datos responsabilidad de la Corporación y sean tratados con la finalidad académica, comercial, investigativa, científica, de proyección social, cultural, recreativa, deportiva, de interés general nacional e internacional, siendo publicados en redes sociales y otros medios de comunicación que utilice la Institución, para las actividades que se enmarquen dentro del objetivo social, académico e institucional, además, autorizo que mis datos personales sean transferidos a terceras entidades tanto nacionales como internacionales con las cuales se tengan convenios y cuya finalidad sea el intercambio de estudiantes, formación académica y profesional.
                          </span>
                        </IonCol>
                        <IonCol size='12' sizeMd='5.5' className=''>
                          <span>
                            De igual modo, declaro que no me encuentro obligado a autorizar el tratamiento de mis datos personales sensibles y que he sido informado que puedo ejercer los derechos de acceso, corrección, supresión, revocación o reclamo por infracción sobre mis datos mediante escrito dirigido a la CORPORACIÓN UNIFICADA NACIONAL DE EDUCACIÓN SUPERIOR - CUN en la dirección de correo electrónico basesdedatos@cun.edu.co indicando en el Asunto el derecho que deseo ejercer, o mediante correo ordinario remitido a la dirección 12B # 4 - 79 de la ciudad de Bogotá D.C. con base en la política de protección de datos, a la cual podré tener acceso en la página web http://www.cun.edu.co.
                          </span>
                          <img className='recursos' src={recurso3}></img>
                        </IonCol>
                      </IonRow>
                    </IonGrid> : <div></div>}
                </SwiperSlide>
                <SwiperSlide>
                  {slide == 2 ?
                    <IonGrid className='gridSwiperTyC'>
                      <IonRow>
                        <IonCol size='12' sizeMd='5.5'>
                          <span>
                            El presente documento establece los Términos y Condiciones del consentimiento informado para la aplicación del Instrumento Prueba Diagnóstica para Vendedores (CheckSeller), por lo que el usuario que realiza la aplicación del instrumento debe realizarlo de acuerdo con los acuerdos aquí señalados.
                            <br></br>
                            Al acceder o utilizar Prueba Diagnóstica para Vendedores (CheckSeller), el usuario acepta plenamente y sin reservas el acuerdo con los términos del consentimiento informado. Estos Términos consisten en un acuerdo de confidencialidad y colaboración entre el usuario y el Centro de Bits y Átomos de la CUN, CEBIAC, que abarca el acceso, uso, obtención de resultados y recolección de datos para fines investigativos. En caso de no estar de acuerdo con estos Términos y Condiciones es necesario abstenerse de utilizar la herramienta.
                          </span>
                        </IonCol>
                        <IonCol size='12' sizeMd='5.5' className=''>
                          <img className='recursos' src={recurso4}></img>
                          <br></br>
                          <span>
                            Al hacer uso de esta prueba, usted confirma que comprende y está de acuerdo con:
                            <br></br>
                            1. Confidencialidad: La información recogida por la herramienta se encuentra amparada por el código ético y deontológico del Colegio Colombiano de Psicólogos, el artículo 3 de la ley 1098 de 2006 y lo señalado en el artículo 2°, numeral 5° de la ley 1090 de 2006.
                            <br></br>
                            2. Respeto a la propiedad intelectual: El Centro de Bits y Átomos de la CUN es propietario del derecho de autor del Prueba Diagnóstica para Vendedores (CheckSeller).
                          </span>
                        </IonCol>
                      </IonRow>
                    </IonGrid> : <div></div>}
                </SwiperSlide>
                <SwiperSlide>
                  {slide == 3 ?
                    <IonGrid className='gridSwiperTyC'>
                      <IonRow>
                        <IonCol size='12' sizeMd='5.5'>
                          <span>
                            Esta premisa no se aplica a la información considerada como de dominio público o utilidad pública. El contenido al que puede acceder mediante el uso de la herramienta es confidencial. Su uso, edición y distribución está autorizado únicamente a colaboradores de la CUN o del Centro de Bits y Átomos de la CUN, CEBIAC. El contenido no deberá ser usado para propósitos distintos a los mencionados, alterado o copiado para su reproducción no autorizada, lo cual está expresamente prohibido. Cualquier violación a los lineamientos será investigada y dará lugar a las acciones legales necesarias.
                            <br></br>
                            3. Restricciones de uso: El uso del Prueba Diagnóstica para Vendedores (CheckSeller) está permitido solamente para personas mayores de (18) años.
                          </span>
                        </IonCol>
                        <IonCol size='12' sizeMd='5.5' className='ion-justify-content-center'>
                          <span>
                            Cualquier otra sugerencia, contacto o comentario al respecto de la prueba pueden ser remitidos al correo electrónico de la Unidad de Emprendimiento de la CUN cunbre@cun.edu.co o al final del cuestionario de la prueba en la pregunta de comentarios y sugerencias.
                          </span>
                          <img className='recursos' src={recurso5}></img>
                        </IonCol>
                      </IonRow>
                    </IonGrid> : <div></div>}
                </SwiperSlide>
              </Swiper>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>


  );
};

export default TyC;
