import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonModal, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import './Seccion2.css';

import img1 from '../../assets/imgs/prueba/CHSE1_2.png'
import img2 from '../../assets/imgs/prueba/CHSE2_2.png'
import img3 from '../../assets/imgs/prueba/CHSE3_2.png'
import img4 from '../../assets/imgs/prueba/CHSE4_2.png'
import User from '../../interfaces/user';
import { useState } from 'react';
import { addDoc, collection, doc, increment, updateDoc } from 'firebase/firestore';
import { db } from '../../environments/firebaseConfig';
import { decrypt, encrypt } from '../../App';

interface ContainerProps {
  item: string;
  respuestas: Array<any>
}

let user: User = {
  nombre: '',
  edad: '',
  documento: '',
  correo: '',
  empresas: '',
  experiencia: '',
  estudios: '',
  comercio: '',
  genero: '',
  fecha: '',
  uid: '',
  validaciones: '',
  respuestas: '',
  resultados: {},
}

const copies: any = {
  "prospeccion": {
    0: ['Identifica tu público objetivo: Si tienes dificultades para prospectar, es importante que definas claramente tu público objetivo. Investiga quiénes son tus clientes ideales y enfoca tus esfuerzos de prospección en ellos.', 'Utiliza herramientas de prospección: Hay muchas herramientas de prospección disponibles que pueden ayudarte a identificar clientes potenciales. Utiliza herramientas de búsqueda en línea, directorios de empresas y bases de datos de clientes para obtener una lista de clientes potenciales.', 'Personaliza tu enfoque: Cada cliente es único, por lo que es importante personalizar tu enfoque de prospección. Investiga sobre la empresa y su industria, y crea un mensaje personalizado para cada cliente.'],

    1: ['Ampliar tu red: Si tienes un nivel medio de prospección, es hora de expandir tu red de contactos.Asiste a eventos de la industria, únete a grupos de redes sociales y conecta con profesionales de la industria para aumentar tus oportunidades de prospectar.', 'Ser consistente: La prospección requiere consistencia y dedicación.Establece un horario para dedicar tiempo a la prospección y mantén ese horario de forma constante.', 'Usar técnicas de seguimiento: Una vez que hayas identificado clientes potenciales, es importante que les hagas seguimiento para mantener su interés. Usa técnicas de seguimiento como llamadas, correos electrónicos y mensajes de seguimiento para mantener la comunicación con tus clientes potenciales.'],

    2: ['Ser estratégico: Si has alcanzado un nivel alto de prospección, es importante que seas estratégico en tu enfoque.Identifica los canales de prospección más efectivos para tu industria y enfoca tus esfuerzos en ellos.', 'Busca referencias: Los clientes satisfechos son una de las mejores fuentes de referencias.Pide a tus clientes actuales que te refieran a otros clientes potenciales que puedan estar interesados en tus productos o servicios.', 'Mantén una actitud positiva: La prospección puede ser un proceso difícil y frustrante.Mantén una actitud positiva y enfócate en las oportunidades en lugar de las dificultades.Una actitud positiva puede hacer una gran diferencia en tu éxito como vendedor.']
  },

  "preparacion": {
    0: ['Investigar tu producto o servicio: Es importante que conozcas a fondo el producto o servicio que estás vendiendo para poder responder a las preguntas del cliente con confianza.', 'Conocer a tu cliente: Investiga a tu cliente para entender sus necesidades y preferencias. Esto te ayudará a adaptar tu enfoque de venta para satisfacer sus necesidades específicas.', 'Practicar tu presentación: Practica tu presentación de ventas para que puedas presentar tu producto o servicio con claridad y convicción.'],

    1: ['Mantener un calendario: Mantén un calendario actualizado de tus ventas y de los clientes potenciales que estás abordando. Esto te ayudará a mantener un seguimiento organizado y a planificar tus actividades de venta.', 'Desarrollar habilidades de comunicación: Desarrolla habilidades de comunicación efectiva para poder comunicarte con confianza y persuasión. Esto puede incluir habilidades de negociación, habilidades de escucha activa y habilidades de presentación.', 'Aprender de tus errores: Aprende de tus errores y utiliza estas lecciones para mejorar tu proceso de venta. Mantén un registro de tus ventas y analiza tus resultados para identificar áreas de mejora.'],

    2: ['Desarrollar habilidades de liderazgo: Desarrolla habilidades de liderazgo para poder guiar y motivar a tu equipo de ventas. Esto puede incluir habilidades de coaching y mentoría.', 'Mantenerte actualizado: Mantente actualizado sobre las tendencias del mercado, los cambios en la industria y los avances tecnológicos que pueden afectar tus ventas. Esto te ayudará a mantenerte relevante y competitivo en tu campo.', 'Fomentar la colaboración: Fomenta la colaboración y el trabajo en equipo entre tu equipo de ventas. Esto puede ayudar a generar nuevas ideas y a mejorar el proceso de venta para todos los miembros del equipo.']
  },

  "acercamiento": {
    0: ['Ser amable y educado: El acercamiento debe ser un proceso amistoso y respetuoso. Comienza con una sonrisa y un saludo cálido, y recuerda preguntar cómo puedes ayudar al cliente.', 'Comunicar tu propósito: Comunica claramente el propósito de tu acercamiento, ya sea para presentarte, ofrecer ayuda o para ofrecer un producto o servicio.', 'Hacer preguntas abiertas: Haz preguntas abiertas para comenzar una conversación y para conocer mejor las necesidades del cliente.'],

    1: ['Ser auténtico: El acercamiento debe ser auténtico y basado en la relación con el cliente. Trata de conectarte con el cliente de manera genuina y de entender sus necesidades. Identificar puntos en común: Identifica puntos en común con el cliente, como intereses, pasatiempos o necesidades. Esto puede ayudarte a construir una relación más fuerte y a aumentar la confianza del cliente en ti. Ofrecer valor: Ofrece valor en el acercamiento, ya sea a través de información útil o consejos prácticos. Esto puede ayudarte a establecerte como un recurso valioso para el cliente y a aumentar su disposición a escuchar tu oferta.'],

    2: ['Ser proactivo: Un vendedor con un alto nivel de acercamiento debe ser proactivo en el proceso de ventas. Busca oportunidades para acercarte a los clientes y establecer conexiones significativas.', 'Escuchar activamente: Escucha activamente las necesidades del cliente y haz preguntas para entender mejor su situación. Esto puede ayudarte a ofrecer soluciones personalizadas que satisfagan sus necesidades.', ' Establecer un objetivo claro: Establece un objetivo claro para el acercamiento, ya sea cerrar una venta o establecer una relación a largo plazo con el cliente. Utiliza técnicas de acercamiento efectivas para lograr tu objetivo.']
  },

  "presentacion": {
    0: ['Preparar tus materiales: Prepara tus materiales de presentación con anticipación, incluyendo cualquier presentación de diapositivas o materiales impresos. Esto te ayudará a estar preparado para cualquier imprevisto.', 'Practicar tu discurso: Practica tu discurso de venta para que puedas presentar tus ideas de manera clara y concisa. Esto te ayudará a evitar titubeos o pausas innecesarias.', 'Conocer a tu audiencia: Investiga a tu audiencia para entender sus necesidades y preferencias. Esto te ayudará a personalizar tu presentación y a destacar los puntos que sean más relevantes para ellos.'],

    1: ['Enfatizar los beneficios: Enfatiza los beneficios de tu producto o servicio en lugar de simplemente enumerar sus características. Esto ayudará a los clientes a ver el valor que pueden obtener de tu oferta.', 'Usar ejemplos concretos: Usa ejemplos concretos para ilustrar tus puntos y para hacer que tu presentación sea más memorable. Los ejemplos también pueden ayudar a mostrar cómo tu producto o servicio puede ser aplicado en situaciones reales.', 'Hacer preguntas abiertas: Haz preguntas abiertas para involucrar a tu audiencia en la presentación y para obtener retroalimentación. Esto puede ayudarte a entender mejor las necesidades de tus clientes y a personalizar tu enfoque de venta.'],

    2: ['Hacer una presentación personalizada: Haz una presentación personalizada para cada cliente, adaptando tu mensaje a sus necesidades y objetivos específicos. Esto puede ayudarte a destacar frente a la competencia y a cerrar más ventas.', 'Convertirte en un experto en la industria: Conviértete en un experto en la industria para que puedas brindar información valiosa a tus clientes y demostrar tu conocimiento y experiencia. Esto te ayudará a construir una relación más sólida con tus clientes y a establecerte como un recurso confiable.', 'Ser creativo: Ser creativo en tu presentación, utilizando diferentes formatos o medios para transmitir tus ideas. Por ejemplo, podrías incluir demostraciones en vivo, videos o gráficos para hacer que tu presentación sea más interesante y efectiva.']
  },

  "objeciones": {
    0: ['Prepararte: Si tienes dificultades para manejar objeciones, es importante que te prepares con anticipación. Identifica las objeciones más comunes y prepara respuestas efectivas para cada una de ellas.', 'Escuchar activamente: Escucha activamente las objeciones de los clientes y toma el tiempo para entender su punto de vista. Esto te permitirá abordar tus preocupaciones de manera efectiva.', 'Ser empático: Muestra empatía hacia las preocupaciones del cliente y reconoce su punto de vista. Esto puede ayudarte a construir una relación más fuerte con el cliente y a aumentar su confianza en ti.'],

    1: ['Hacer preguntas: Haz preguntas para entender mejor las preocupaciones del cliente y para ayudarlo a explorar sus propias objeciones. Esto puede ayudarte a descubrir soluciones que satisfagan las necesidades del cliente.', 'Ofrecer soluciones: Ofrece soluciones efectivas y personalizadas para abordar las objeciones del cliente. Esto puede ayudarte a superar las preocupaciones del cliente y a cerrar la venta.', 'Practicar: Práctica el manejo de objeciones con colegas o amigos para mejorar tus habilidades. Escucha su retroalimentación y utiliza sus consejos para mejorar tu enfoque.'],

    2: ['Anticiparte a las objeciones: Si has alcanzado un nivel alto de manejo de objeciones, es importante que puedas anticiparte a las objeciones de los clientes. Aprende a leer las señales del cliente y a identificar las preocupaciones potenciales para poder abordarlas con anticipación.', 'Enfocarte en los beneficios: Enfócate en los beneficios de tus productos o servicios para ayudar a los clientes a comprender el valor que pueden obtener. Esto puede ayudarte a superar las objeciones del cliente y a cerrar la venta.', 'Ser persistente: A veces, el manejo de objeciones puede requerir persistencia. Continúa trabajando con el cliente para abordar sus preocupaciones y para encontrar soluciones que satisfagan sus necesidades.La persistencia puede ser clave para cerrar la venta.']
  },

  "cierre": {
    0: ['Conocer tu producto: Es importante que conozcas bien tu producto o servicio para poder comunicar claramente sus beneficios y convencer al cliente de que lo necesita.', 'Escuchar al cliente: Escucha con atención las necesidades del cliente y cómo tu producto o servicio puede satisfacerlas. Presta atención a sus respuestas y usa esta información para hacer una oferta que sea atractiva para ellos.', 'Ser claro y directo: Una vez que hayas presentado tu oferta, sé claro y directo al pedir la venta. Evita ser vago o indeciso, ya que esto puede hacer que el cliente pierda la confianza en ti.'],

    1: ['Conocer a tu cliente: Toma el tiempo para conocer bien a tu cliente y sus necesidades. Usa esta información para adaptar tu oferta a sus necesidades específicas.', 'Aprender a cerrar: Aprende diferentes técnicas de cierre, como el cierre por alternativa, el cierre por urgencia o el cierre por tiempo limitado. Práctica estas técnicas para encontrar la que funcione mejor para ti y para cada situación.', 'Ofrecer garantías: Ofrece garantías o devoluciones de dinero para tranquilizar al cliente y hacerles sentir más cómodos al tomar una decisión de compra.'],

    2: ['Crear una relación de confianza: Construye una relación de confianza con el cliente al escuchar sus necesidades, brindar soluciones personalizadas y demostrar tu experiencia en el tema.', 'Cerrar durante todo el proceso de venta: Utiliza técnicas de cierre durante todo el proceso de venta para asegurarte de que estás avanzando hacia la venta final. No esperes hasta el final para pedir la venta.', 'Hacer seguimiento: Una vez que hayas cerrado la venta, haz un seguimiento con el cliente para asegurarte de que están satisfechos con la compra y que no tienen ninguna pregunta o problema. Esto también puede ayudarte a mantener una relación a largo plazo con el cliente y a generar referencias o ventas futuras.']
  },

  "seguimiento": {
    0: ['Realizar un seguimiento de forma regular: El seguimiento debe ser un proceso regular y constante. Por lo tanto, asegúrate de establecer un calendario claro de seguimiento para cada cliente potencial.', 'Asegurarte de que el cliente tenga toda la información necesaria: Asegúrate de que el cliente tenga toda la información necesaria para tomar una decisión informada. Esto puede incluir detalles sobre el producto o servicio, precios, términos y condiciones, entre otros.', 'Ser persistente: No te rindas fácilmente si el cliente no responde a tus correos electrónicos o llamadas. Continúa haciendo seguimiento y ofreciendo información adicional para ayudar al cliente a tomar una decisión.'],

    1: ['Personalizar el seguimiento: Personaliza el seguimiento para cada cliente potencial y ten en cuenta sus necesidades y deseos específicos. Esto puede incluir enviar correos electrónicos personalizados o proporcionar información adicional sobre áreas específicas de interés.', 'Usar múltiples canales de comunicación: Utiliza múltiples canales de comunicación, como correo electrónico, teléfono y mensajes directos, para mantener el contacto con el cliente potencial. Esto puede ayudar a aumentar la probabilidad de que te respondan.', 'Ofrecer valor adicional: Ofrece valor adicional al cliente potencial, como información sobre tendencias del mercado o soluciones a problemas que no estén directamente relacionados con tu oferta. Esto puede ayudar a construir una relación más sólida con el cliente.'],

    2: ['Ser proactivo: Ser proactivo en el seguimiento de tus clientes potenciales y no esperes a que ellos te contacten. Siempre busca oportunidades para ofrecer información adicional o resolver cualquier duda que puedan tener.', 'Ser estratégico: Desarrolla una estrategia clara de seguimiento que se adapte a las necesidades de cada cliente potencial. Por ejemplo, puede que algunos clientes prefieran un seguimiento más frecuente, mientras que otros prefieren un seguimiento menos frecuente.', 'Ofrecer una solución personalizada: Ofrece una solución personalizada al cliente potencial que aborde sus necesidades y deseos específicos. Esto puede ayudar a aumentar la probabilidad de que cierre la venta y a construir una relación sólida a largo plazo.']
  }
}

const preguntas: any = {
  41: {
    pregunta: 'A futuro, la manera como actuó el vendedor fue:',
    opciones: [
      'incorrecta, ya que debió prospectar nuevos clientes a pesar de que eso le implicara no ganar el reconocimiento por mejor vendedor del mes.',
      'correcta, porque centró todos los esfuerzos en una tarea y eso le permitió ganar el reconocimiento a mejor vendedor del mes.',
      'incorrecta, porque el interés de la compañía es trabajar en equipo y el bajo apoyo a otros compañeros provocó que no se alcanzara la meta mensual.',
      'correcta, porque se motivó a seguir cumpliendo la meta del mes hasta que logró su objetivo.'],
    correcta: 'incorrecta, ya que debió prospectar nuevos clientes a pesar de que eso le implicara no ganar el reconocimiento por mejor vendedor del mes.'
  },
  42: {
    pregunta: '¿Cuáles cree que fueron los canales de prospección más efectivos que pudo haber usado el vendedor para cumplir su meta en ventas?',
    opciones: [
      'Correo electrónico y Referidos',
      'WhatsApp personal y Ferias empresariales',
      'Visitas presenciales esporádicas en las empresas',
      'LinkedIn y prospectos de mercado natural'],
    correcta: 'LinkedIn y prospectos de mercado natural'
  },
  43: {
    pregunta: 'En el mes de febrero el vendedor tuvo errores de práctica en:',
    opciones: [
      'prospección, porque debió cumplir con dicha tarea para tener posibles clientes en febrero y marzo ya que estos meses son de bajas ventas.',
      'cierre, porque debió acompañar a sus compañeros a cumplir la meta grupal ya que el trabajo en equipo es un valor fundamental.',
      'seguimiento, porque no verificó si el producto fue despachado correctamente y se cumplieron las expectativas de negociación.',
      'se desarrolló todo el proceso adecuadamente por ello obtuvo el reconocimiento a vendedor del mes.'],
    correcta: 'cierre, porque debió acompañar a sus compañeros a cumplir la meta grupal ya que el trabajo en equipo es un valor fundamental.'
  },
  44: {
    pregunta: 'La objeción de mayor peso que manejó el cliente fue:',
    opciones: [
      'No tener a su esposa, quien podría tomar la decisión.',
      'No tener el dinero suficiente para hacer la compra.',
      'No tener tiempo para esperar el estudio de crédito.',
      'Desconfianza en la marca, por referencias de amigos.'],
    correcta: 'No tener el dinero suficiente para hacer la compra.'
  },
  45: {
    pregunta: 'El vendedor tuvo errores de práctica en:',
    opciones: ['Preparación', 'Acercamiento', 'Prospección', 'Cierre'],
    correcta: 'Acercamiento'
  },
  46: {
    pregunta: 'El principal motivo que pudo incentivar al cliente a continuar con la compra fue:',
    opciones: [
      'Haberse alineado con uno de los factores de decisión de compra del vehículo.',
      'Dar la promesa de valor de la empresa para que reevalúe el costo de adquisición del producto.', 'Tener estudios de crédito en menos de una hora ya que el cliente es una persona muy ocupada.', 'Realizar un adecuado proceso de acercamiento con el cliente para identificar sus necesidades.'],
    correcta: 'Dar la promesa de valor de la empresa para que reevalúe el costo de adquisición del producto.'
  },
  47: {
    pregunta: 'El vendedor pudo tener aspectos a mejorar en la fase de:',
    opciones: ['Preparación.', 'Prospección.', 'Acercamiento.', 'Negociación.'],
    correcta: 'Acercamiento.'
  },
  48: {
    pregunta: 'El hecho de haber respondido “ha sido un tema que ha afectado a todos inclusive su empresa porque suben los precios del producto pero que esperan que se estabilice en dos semanas” fue:',
    opciones: [
      'Correcto, porque fue sincero con el cliente y el hecho de haber omitido esa información pudo representar un problema a futuro.',
      'Incorrecto, porque no debió dar información adicional si no se la preguntaron por ello no pudo cerrar la venta.',
      'Correcto, porque no dejó que el cliente percibiera su temor a abrir la reunión y respondió a su comentario.',
      'Incorrecto porque asoció la pregunta del cliente a un atributo principal del producto para cerrar la venta.'],
    correcta: 'Incorrecto porque asoció la pregunta del cliente a un atributo principal del producto para cerrar la venta.'
  },
  49: {
    pregunta: 'La mejor manera con la que Carlos puede sortear esta objeción es:',
    opciones: [
      'Cambiar el enfoque de la presentación para tratar de encontrar un nuevo escenario y llegar a lograr lo que el cliente espera.',
      'Al ser política de la compañía lo mejor es hablarle de otros productos de la empresa y desistir del discurso inicial según la objeción detectada.',
      'Indagar por qué es política de la compañía, encontrando así la oportunidad de mostrar los atributos adicionales de la solución.',
      'Sugerirle una empresa que si trabaja con esa marca y le presenta el resto del portafolio.'],
    correcta: 'Indagar por qué es política de la compañía, encontrando así la oportunidad de mostrar los atributos adicionales de la solución.'
  },
  50: {
    pregunta: '¿Cuál debería ser el accionar de Carlos al recibir la solicitud de cortar la reunión? ',
    opciones: [
      'Terminar el discurso y llevarse el número de teléfono del gerente para llamarlo después.',
      'Usar el portafolio para encontrar la manera de atrapar nuevamente la atención del interlocutor.', 'Terminar su discurso, pero asegurarse antes de salir un siguiente encuentro comercial.',
      ' Terminar el discurso y como no hay interés emplear el tiempo con otro cliente que si le genere un cierre efectivo.'],
    correcta: 'Terminar su discurso, pero asegurarse antes de salir un siguiente encuentro comercial.'
  }
}

const Seccion2: React.FC<ContainerProps> = ({ item, respuestas }) => {
  user = JSON.parse(decrypt(sessionStorage.getItem('user')!))
  const [showLoading, setShowLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const history = useHistory();
  const alfabeto = ['A', 'B', 'C', 'D']

  let infoSapiolab = {
    coleccion: 'demoCheckseller',
    uidDoc: '',
    tipo: '',
  }

  let resultados = {
    'prospeccion': {
      puntaje: '',
      copy: [],
      copyDemo: ''
    },
    'preparacion': {
      puntaje: '',
      copy: [],
      copyDemo: ''
    },
    'acercamiento': {
      puntaje: '',
      copy: [],
      copyDemo: ''
    },
    'presentacion': {
      puntaje: '',
      copy: [],
      copyDemo: ''
    },
    'objeciones': {
      puntaje: '',
      copy: [],
      copyDemo: ''
    },
    'cierre': {
      puntaje: '',
      copy: [],
      copyDemo: ''
    },
    'seguimiento': {
      puntaje: '',
      copy: [],
      copyDemo: ''
    },
    'situacionales': {
      puntaje: '',
    }
  }

  let options = [...preguntas[item]['opciones'].sort(function () { return Math.random() - 0.5 })];
  let select: string = '';
  const getValue = (value: string) => {
    select = value
  }

  const getCopy = (percent: string, key: string) => {
    let copy: any = ''
    // console.log(percent)
    if (parseFloat(percent) <= 33.3) {
      copy = copies[key][0]
    } else if (parseFloat(percent) > 33.3 && parseFloat(percent) <= 66.7) {
      copy = copies[key][1]
    } else if (parseFloat(percent) > 66.7) {
      copy = copies[key][2]
    }
    // console.log(copy)
    return copy
  }

  const saveResults = async (user: User) => {
    let todayDate = new Date().toISOString();
    setShowLoading(true);
    infoSapiolab = JSON.parse(decrypt(sessionStorage.getItem('infoSapiolab')!));
    await addDoc(collection(db, infoSapiolab.coleccion), {
      nombre: user.nombre,
      edad: user.edad,
      documento: user.documento,
      correo: user.correo,
      empresas: user.empresas,
      experiencia: user.experiencia,
      estudios: user.estudios,
      genero: user.genero,
      uid: infoSapiolab.uidDoc,
      fecha: todayDate,
      validaciones: sessionStorage.getItem('validaciones')!,
      respuestas: user.respuestas,
      resultados: user.resultados,
    })
      .then((doc) => {
        setShowLoading(false)
        history.push('/resultados');
        let resultados = JSON.parse(user.resultados)
        const apiUrl = 'https://cebiacapi.herokuapp.com/emailCheckseller';
        const requestOptions = {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: user.correo,
            link: 'https://checkseller.web.app/home?r=' + doc.id,
            name: user.nombre,
            prospeccion: resultados.prospeccion.copy,
            preparacion: resultados.preparacion.copy,
            acercamiento: resultados.acercamiento.copy,
            presentacion: resultados.presentacion.copy,
            objeciones: resultados.objeciones.copy,
            cierre: resultados.cierre.copy,
            seguimiento: resultados.seguimiento.copy,
          })
        };
        fetch(apiUrl, requestOptions)
          .then(response => response.json())
          .then((data) => {
            //console.log(data)
          });
      })
  }

  const chooseOpt = (letter: string) => {
    ['A', 'B', 'C', 'D'].forEach(element => {
      if (element == letter) {
        document.getElementById(element)!.style.background = "#03a66a";
        document.getElementById(element)!.style.color = "white";
      } else {
        document.getElementById(element)!.style.background = "transparent";
        document.getElementById(element)!.style.color = "black";
      }
    });
  }

  const continuar = () => {
    if (select == '') {
      alert('Selecciona una opción para continuar')
    } else {
      ['A', 'B', 'C', 'D'].forEach(element => {
        document.getElementById(element)!.style.background = "transparent";
        document.getElementById(element)!.style.color = "black";
      });
      select == preguntas[item]['correcta'] ? respuestas[parseInt(item) - 1] = 1 : respuestas[parseInt(item) - 1] = 0
      let respuestasEnc = encrypt(JSON.stringify(respuestas))
      sessionStorage.setItem('respuestas', respuestasEnc)
      select = ''
      if (item == '43') {
        history.push('/situacion/2')
      } else if (item == '44') {
        history.push('/validacion/3')
      } else if (item == '46') {
        history.push('/situacion/3')
      } else if (item == '48') {
        history.push('/situacion/4')
      } else if (item == '50') {

        resultados.prospeccion.puntaje = (((respuestas.slice(0, 6).reduce((a, b) => a + b, 0)) * 100) / 30).toFixed(1);
        resultados.preparacion.puntaje = (((respuestas.slice(6, 9).reduce((a, b) => a + b, 0)) * 100) / 15).toFixed(1);
        resultados.acercamiento.puntaje = (((respuestas.slice(9, 16).reduce((a, b) => a + b, 0)) * 100) / 35).toFixed(1);
        resultados.presentacion.puntaje = (((respuestas.slice(16, 20).reduce((a, b) => a + b, 0)) * 100) / 20).toFixed(1);
        resultados.objeciones.puntaje = (((respuestas.slice(20, 26).reduce((a, b) => a + b, 0)) * 100) / 30).toFixed(1);
        resultados.cierre.puntaje = (((respuestas.slice(26, 32).reduce((a, b) => a + b, 0)) * 100) / 30).toFixed(1);
        resultados.seguimiento.puntaje = (((respuestas.slice(32, 40).reduce((a, b) => a + b, 0)) * 100) / 40).toFixed(1);
        resultados.situacionales.puntaje = (((respuestas.slice(40, 51).reduce((a, b) => a + b, 0)) * 100) / 10).toFixed(1);

        resultados.prospeccion.copy = getCopy(resultados.prospeccion.puntaje, 'prospeccion')
        resultados.preparacion.copy = getCopy(resultados.preparacion.puntaje, 'preparacion')
        resultados.acercamiento.copy = getCopy(resultados.acercamiento.puntaje, 'acercamiento')
        resultados.presentacion.copy = getCopy(resultados.presentacion.puntaje, 'presentacion')
        resultados.objeciones.copy = getCopy(resultados.objeciones.puntaje, 'objeciones')
        resultados.cierre.copy = getCopy(resultados.cierre.puntaje, 'cierre')
        resultados.seguimiento.copy = getCopy(resultados.seguimiento.puntaje, 'seguimiento')

        user.respuestas = JSON.stringify(respuestas)
        user.resultados = JSON.stringify(resultados)

        let userEnc = encrypt(JSON.stringify(user))
        sessionStorage.setItem('user', userEnc)
        // console.log(resultados)
        saveResults(user)
      } else {
        history.push('/items/' + (parseInt(item) + 1))
      }
    }
  }

  return (
    <IonGrid className='gridSeccion2'>

      <IonRow className='rowSeccion2 ion-justify-content-center'>
        <IonCol size='12' sizeMd='8' className='ion-justify-content-center ion-align-self-center'>
          <IonRow>
            <IonCol size='12' className='ion-margin-vertical'>
              <b>¿Necesitas recordar la situación?</b>
              <IonIcon className='iconMore' color='danger' icon={addCircleOutline} onClick={() => setIsOpenModal(true)}></IonIcon>
            </IonCol>
            <IonCol size='12'>
              <span>{preguntas[item]['pregunta']}</span>
            </IonCol>
            <IonCol size='12'>
              <IonRadioGroup value="" onIonChange={e => getValue(e.detail.value)}>
                <IonRow>
                  {
                    options.map((opt, index) =>
                      <IonCol key={opt} size='12' className='ion-no-padding'>
                        <IonRow>
                          <IonCol size='2' className='ion-justify-content-center ion-align-self-center ion-text-center'>
                            <span id={alfabeto[index]} className='index'>{alfabeto[index]}</span>
                          </IonCol>
                          <IonCol size='10' className='colSecc2 ion-no-padding'>
                            <IonItem className='opcSeccion2' onClick={() => chooseOpt(alfabeto[index])}>
                              <IonLabel className='labelOptSeccion2 ion-text-wrap'>
                                <h2 className=''>{options[index]}</h2>
                                <IonRadio id={String(opt)} className='radioSelect2' value={opt}></IonRadio>
                              </IonLabel>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    )
                  }
                </IonRow>
              </IonRadioGroup>
            </IonCol>
          </IonRow>
        </IonCol>

        <IonCol size='12' sizeMd='4' className='ion-align-self-center ion-padding'>
          {(parseInt(item) >= 41 && parseInt(item) <= 43) &&
            <img className='imgSeccion2' src={img1}></img>
          }
          {(parseInt(item) > 43 && parseInt(item) <= 46) &&
            <img className='imgSeccion2' src={img2}></img>
          }
          {(parseInt(item) > 46 && parseInt(item) <= 48) &&
            <img className='imgSeccion2' src={img3}></img>
          }
          {(parseInt(item) >= 49) &&
            <img className='imgSeccion2' src={img4}></img>
          }
        </IonCol>

        <IonCol size='12' className='ion-no-padding ion-text-center'>
          <IonButton color='btncheck' onClick={continuar}>Siguiente</IonButton>
        </IonCol>
      </IonRow>

      <IonModal isOpen={isOpenModal}>
        <IonContent className="ion-padding">
          <div className='ion-padding'>
            <h2>Situación:</h2>
            <IonButton className='close' size='small' color='danger' onClick={() => setIsOpenModal(false)}>X</IonButton>
            {(parseInt(item) >= 41 && parseInt(item) <= 43) &&
              <p className='pSeccion2'>Usted es vendedor de soluciones de software en una compañía de EE. UU., la filosofía de dicha empresa es trabajar en equipo para lograr las metas del mes, por ello, la gerencia comercial, estructura unos beneficios por el cumplimiento de la meta grupal y sistema de comisión individual. En el mes de febrero su equipo no logró cumplir la meta, pero usted rebasó sus ventas, se convirtió en el empleado del mes y recibió un beneficio económico. Aun así, usted reconoce que en febrero dejó de lado otras labores planeadas, que influyen en los resultados del mes siguiente, ya que se enfocó solo en cerrar ventas.</p>
            }
            {(parseInt(item) > 43 && parseInt(item) <= 45) &&
              <p className='pSeccion2'>Usted es vendedor de automóviles de una multinacional alemana, llega al concesionario un comprador quien es dueño de varios terrenos del sector, él le expresa que quiere regalarle un auto a su mamá pero solo su esposa conoce los gustos de ella, al mostrarle las referencias disponibles, él indica que todas salen de su presupuesto, a lo que usted responde que esos son los precios que establece la compañía por lo que lastimosamente solo podría hacer una cosa al respecto, ofrecerle un crédito que es aprobado el mismo día, al llevarlo al área financiera estos hacen su respectiva recepción de documentos y le indican que en un máximo de 3 días recibirá respuesta, el cliente agarra su maleta y dice que prefiere ir a otro concesionario porque no confía en dicha marca.</p>
            }
            {(parseInt(item) > 45 && parseInt(item) <= 47) &&
              <p className='pSeccion2'>Lucas es un vendedor que visita comercios que distribuyen bebidas alcohólicas, en esta ocasión identificó que se están haciendo las adecuaciones de una bodega para inaugurar una distribuidora mayorista de licores, tras varios intentos, logró conseguir el número del jefe de compras y concretar una cita. Al llegar la fecha, Lucas verifica desde temprano que lleve todos los elementos necesarios para la presentación del producto, al encontrarse con el jefe de compras pregunta si están todos los interesados en la reunión y ante su respuesta afirmativa procede a comenzar, el jefe de compras estrecha su mano y le pregunta si ha visto la noticia de la alza del dólar a lo que Lucas responde que ha sido un tema que ha afectado a todos inclusive su empresa porque suben los precios del producto pero que esperan que se estabilice en dos semanas, el jefe de compras le indica que acaba de recordar una reunión que tenía programada que si podían reprogramar esa para dentro de dos semanas.</p>
            }
            {(parseInt(item) >= 48) &&
              <p className='pSeccion2'>Carlos logra una cita con el gerente de tecnología de una universidad, lo cual es muy positivo porque la compañía es muy fuerte en el sector educativo, cuando inicia la reunión detecta que es una institución que está casada con una marca con la que la compañía no tiene ningún convenio, y es política de esta continuar igual. Sin embargo, le permiten a Carlos continuar con su presentación, pero a la mitad de la reunión y dado la objeción presentada el gerente le pide, luego de unos minutos, cortar la reunión porque se le presento algo urgente.</p>
            }
          </div>
        </IonContent>
      </IonModal>

      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Guardando tus respuestas......'}
      />

    </IonGrid>
  );
};
export default Seccion2;
