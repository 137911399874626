import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import './Intro.css';
import logo from '../assets/imgs/intro/logo.png'
import 'animate.css';
import { useHistory } from 'react-router';


const Intro: React.FC = () => {

  const history = useHistory();
  const continuar = () => {
    history.push('/registro')
  }

  return (
    <IonPage>
      <IonContent fullscreen className='contentIntro'>
        <IonGrid className='gridIntro'>
          <IonRow className='rowIntro'>
            <IonCol size='12' sizeMd='6' className='ion-justify-content-center ion-align-self-center'>
              <IonRow className='ion-padding-horizontal'>
                <IonCol size='12' class='infoIntro ion-text-center'>
                  <span>Ahora que tomaste la decisión de hacer un diagnóstico de tu proceso de ventas, <b> presta atención</b> a las siguientes instrucciones antes de empezar la prueba.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Recuerda, no hay respuestas correctas o incorrectas, sólo debes responder con la mayor <b> sinceridad</b> posible.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    <b>No se guardarán las respuestas</b> hasta que no finalices el test por completo.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Nuestras pruebas cuentan con un método para identificar si tus respuestas son <b> confiables</b>. En caso de encontrar incoherencias,
                    tendrás que resolver nuevamente toda la prueba.
                  </span>
                  <br></br>
                  <br></br>
                  <small>*Estas incoherencias hacen referencia a cuando contestas a conveniencia
                    o respondes indiscriminadamente a las afirmaciones*</small>
                </IonCol>
              </IonRow>
            </IonCol>

            <IonCol size='12' sizeMd='6' className='ion-align-self-center ion-padding'>
              <img className='imgIntro animate__animated animate__bounceIn' src={logo}></img>
            </IonCol>

            <IonCol size='12' className='ion-no-padding ion-text-center'>
              <IonButton color='btncheck' onClick={continuar}>Continuar</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>


  );
};

export default Intro;
