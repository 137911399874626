import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow } from '@ionic/react';
import './Validacion.css';
import { useHistory } from 'react-router';

interface ContainerProps {
  item: string;
  validaciones: Array<any>
}

const Validacion: React.FC<ContainerProps> = ({ item, validaciones }) => {

  const history = useHistory();
  const preguntas: any = {
    '1': {
      pregunta: 'A continuación responderé "Nunca" porque estoy atento en esta prueba',
      opciones: ['Nunca', 'Casi Nunca', 'A veces', 'Casi siempre', 'Siempre'],
      correcta: 'Nunca'
    },
    '2': {
      pregunta: 'Si estoy atento a este cuestionario, responderé la opción "Siempre"',
      opciones: ['Nunca', 'Casi Nunca', 'A veces', 'Casi siempre', 'Siempre'],
      correcta: 'Siempre'
    },
    '3': {
      pregunta: 'En esta pregunta verificaremos si está atento, responde "Casi siempre"',
      opciones: ['Nunca', 'Casi Nunca', 'A veces', 'Casi siempre', 'Siempre'],
      correcta: 'Casi siempre'
    },
  }

  let options = [1, 2, 3, 4, 5];
  let descp = ['Nunca', 'Casi nunca', 'A veces', 'Casi siempre', 'Siempre'];
  let select: number = 0

  const getValue = (value: number) => {
    select = value
    // console.log(select)
  }

  const continuar = () => {
    if (select == 0) {
      alert('Selecciona una opción para continuar')
    } else {
      select == preguntas[item]['correcta'] ? validaciones[parseInt(item) - 1] = 1 : validaciones[parseInt(item) - 1] = 0
      sessionStorage.setItem('validaciones', JSON.stringify(validaciones))
      select = 0

      if (item == '1') {
        history.push('/items/15')
      } else if (item == '2') {
        history.push('/items/30')
      } else if (item == '3') {
        history.push('/items/45')
      }
    }
  }

  return (
    <IonGrid className='gridValidacion'>
      <IonRow className='rowValidacion ion-justify-content-center'>

        <IonCol size='10' className='ion-justify-content-center ion-align-self-center ion-text-center ion-margin'>
          <b className='ion-padding-vertical'>{preguntas[item]['pregunta']}</b>
        </IonCol>
        <IonCol size='11' className='ion-align-self-center ion-padding'>
          <IonRadioGroup value="" onIonChange={e => getValue(e.detail.value)}>
            <IonRow>

              {options.map((opt, index) => <IonCol key={opt} sizeMd='2.4' size='12' className='opc ion-no-padding'>
                <IonRow className='opcRow'>
                  <IonCol size='12' className='opcCol ion-no-padding'>
                    <IonItem className="itemOpt">
                      <IonLabel className='labelOpt ion-text-center'>
                        <h2 className='headerOpt'>{options[index]}</h2>
                        <h2 className='bodyOpt'>{descp[index]}</h2>
                        <IonRadio id={String(opt)} className='radioSelect' value={descp[index]}></IonRadio>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonCol>
              )}

            </IonRow>
          </IonRadioGroup>
        </IonCol>
        <IonCol size='12' className='ion-no-padding ion-text-center'>
          <IonButton color='btncheck' onClick={continuar}>Siguiente</IonButton>
        </IonCol>

      </IonRow>
    </IonGrid>
  );
};

export default Validacion;
