import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonModal, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory } from 'react-router';
import User from '../interfaces/user';

import logo from '../assets/imgs/logo.png'
import logoSapiolab from '../assets/imgs/resultados/logo.png'

import './Resultados.css';
import 'animate.css';
import React, { useEffect } from 'react';
import { home } from 'ionicons/icons';
import { decrypt } from '../App';

const Resultados: React.FC = () => {
  if(!sessionStorage.getItem('user')!){
    const host: string =  window.location.origin;
    window.location.replace(host)
  }

  const [isOpenValidacion, setIsOpenValidacion] = React.useState(false)
  const history = useHistory();
  let user: User = {
    nombre: '',
    edad: '',
    documento: '',
    correo: '',
    empresas: '',
    experiencia: '',
    estudios: '',
    comercio: '',
    genero: '',
    fecha: '',
    uid: '',
    validaciones: '',
    respuestas: '',
    resultados: {}
  };

  user = JSON.parse(decrypt(sessionStorage.getItem('user')!))
  let resultados = JSON.parse(user.resultados)
  let validaciones = user.validaciones

  useEffect(() => {
    // console.log(validaciones)
    if (validaciones[1] == '0' && validaciones[3] == '0' && validaciones[5] == '0') {
      setIsOpenValidacion(true)
    }
  }, [])

  // console.log(user)

  return (
    <IonPage>
      <IonContent fullscreen className='contentResultados'>
        <IonGrid className='gridResultados'>
          <IonRow className='rowResultados'>

            <IonCol size='12' className='ion-text-center ion-align-self-center ion-padding'>
              <img className='imgResult animate__animated animate__bounceIn' src={logo}></img>
            </IonCol>

            <IonCol size='9' className='colText1 ion-text-center ion-padding'>
              <span>
                <b>Resultado finales</b>
              </span>
              <br></br>
              <span>
                {user.nombre}
              </span>
              <br></br>
              <span>
                {user.fecha.split('T')[0]}
              </span>
            </IonCol>

            <IonCol size="11" className='colText2 ion-text-center'>
              <p>
                Desarrollado por Sapiolab
              </p>
              <img style={{ maxWidth: '50px' }} src={logoSapiolab}></img>
            </IonCol>

            <IonCol className='ion-text-center'>
              <p>¡Haz completado la demostración de la prueba! Te hemos enviado un correo a tu email: <b>{user.correo}</b> con las instrucciones para obtener los resultados completos de tu prueba. Recuerda revisar la bandeja de spam en que caso de que no recibas en correo en la bandeja principal.</p>
            </IonCol>
            <IonCol size='12' className='ion-text-center'>
              <IonIcon onClick={() => {history.push('/home')}} className='icon-home' icon={home}></IonIcon>
            </IonCol>
          </IonRow>

        </IonGrid>

         <IonModal className='modal-autoheight' isOpen={isOpenValidacion} onDidDismiss={() => { setIsOpenValidacion(false); validaciones = '' }}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>¡Advertencia!</IonTitle>
              <IonButtons slot="end">
                <IonButton color="danger" onClick={() => { setIsOpenValidacion(false); validaciones = '' }}>X</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="modalInfo ion-padding ion-align-self-center">
            <IonGrid className='gridModalInfo ion-align-self-center'>
              <IonRow className='ion-align-self-center'>
                <IonCol className='ion-text-center'>
                  <p>Es probable que sus datos no sean confiables ya que el sistema ha identificado incoherencias en sus respuestas esto puede suceder porque contesta a conveniencia o responde indiscriminadamente a las afirmaciones. Si lo desea, vuelva a resolver la prueba con mayor concentración y en un espacio tranquilo. </p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>


  );
};

export default Resultados;
