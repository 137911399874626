import {
  IonHeader,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { enterOutline } from "ionicons/icons";

import logo1 from "../assets/imgs/home/logo1.png";
import logo2 from "../assets/imgs/home/logo2.png";
import sello from "../assets/imgs/home/sello.png";

import { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../environments/firebaseConfig";
import { useHistory } from "react-router";

import "./Home.css";
import "animate.css";
import { encrypt } from "../App";

let dataSearch: Array<any> = [];

const Home: React.FC = () => {
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [value, setValue] = useState("");
  let infoSapiolab = {
    coleccion: "demoCheckseller",
    uidDoc: "",
    tipo: "",
  };

  const verPruebasDisponibles = async () => {
    // console.log(infoSapiolab)
    let infoSapiolabEnc = encrypt(JSON.stringify(infoSapiolab));
    sessionStorage.setItem("infoSapiolab", infoSapiolabEnc);
    setShowLoading(false);
    history.push("/intro");
  };

  const verificarQuery = () => {
    let location = window.location.search;
    let params = new URLSearchParams(location);

    if (params.has("d")) {
      setShowLoading(true);
      infoSapiolab.tipo = "empresas"; //Primera letra de URL despues de ? define si es empresa o persona
      infoSapiolab.uidDoc = params.get("e")!; //UID de la cuenta de la emrpesas o persona que genero el enlace
      verRepoSapiolab();
    }
  };

  const verificarURL = () => {
    let location = window.location.search;
    let params = new URLSearchParams(location);

    if (location == "") {
      alert("El enlace es incorrecto, por favor solicite uno nuevo");
    } else {
      if (params.has("e")) {
        setShowLoading(true);
        infoSapiolab.tipo = "empresas"; //Primera letra de URL despues de ? define si es empresa o persona
        infoSapiolab.uidDoc = params.get("e")!; //UID de la cuenta de la emrpesas o persona que genero el enlace
        verPruebasDisponibles();
      } else {
        alert("El enlace es incorrecto, por favor solicite uno nuevo");
      }
    }
  };

  const verRepoSapiolab = async () => {
    const docRef = doc(db, "demoCheckseller", infoSapiolab.uidDoc);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let docData = docSnap.data();
      let dataEnc = encrypt(JSON.stringify(docData));
      sessionStorage.setItem("user", dataEnc);
      history.push("/resultados");
    } else {
      alert("El enlace es incorrecto, por favor solicite uno nuevo");
    }
  };

  const checkRepoInput = async () => {
    dataSearch = [];
    const q = query(
      collection(db, "demoCheckseller"),
      where("documento", "==", value)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      dataSearch.push(doc.data());
    });

    if (dataSearch.length != 0) {
      if (dataSearch.length == 1) {
        // console.log(dataSearch[0])
        let data = dataSearch[0];
        let dataEnc = encrypt(JSON.stringify(data));
        sessionStorage.setItem("user", dataEnc);
        setIsOpenModal(false);
        const host: string = window.location.origin;
        window.open(host + "/resultados", "_self");
      } else {
        // console.log(dataSearch[0].fecha)
        setIsOpenModal(true);
      }
    } else {
      alert(
        "No existe una prueba realizada con este número de documento, por favor revisa los datos ingresados"
      );
    }
  };

  const goToResult = (index: number) => {
    let data = dataSearch[index];
    let dataEnc = encrypt(JSON.stringify(data));
    sessionStorage.setItem("user", dataEnc);
    // console.log(dataSearch)
    setIsOpenModal(false);
    const host: string = window.location.origin;
    window.open(host + "/resultados", "_self");
    // history.push('/resultados');
  };

  useEffect(() => {
    sessionStorage.clear();
    dataSearch = [];
    verificarQuery();
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen className="contentHome">
        <IonGrid className="gridHome">
          <IonRow className="rowHome">
            <IonCol
              size="12"
              sizeMd="7.5"
              className="ion-justify-content-center ion-align-self-center"
            >
              <IonRow className="ion-padding-horizontal">
                <IonCol size="12">
                  <IonRow className="ion-text-center">
                    <IonCol size="12">
                      <span>¡Oye tú!</span>
                      <br></br>
                      <span>
                        ¿Eres vendedor y buscas mejorar el cumplimiento de tus
                        metas?
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Si es así,{" "}
                        <b>CheckSeller es la herramienta que necesitas,</b> da
                        clic en “empezar la prueba” y explora cómo mejorar tus
                        habilidades comerciales{" "}
                      </span>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="ion-margin-vertical ion-no-padding ion-text-center"
                    >
                      <IonButton color="btncheck" onClick={verificarURL}>
                        Empezar la prueba
                      </IonButton>
                    </IonCol>
                    <IonCol size="12" className="logoCol">
                      <img
                        className="ion-hide-md-up sello animate__animated animate__bounceIn"
                        src={sello}
                      ></img>
                      <img
                        className="logo1 animate__animated animate__bounceIn"
                        src={logo1}
                      ></img>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonCol>

            <IonCol
              size="0"
              sizeMd="4.5"
              className="ion-align-self-end logoCol"
            >
              <img
                className="sello ion-hide-md-down animate__animated animate__bounceIn"
                src={sello}
              ></img>
              <img
                className="logo2 animate__animated animate__bounceIn"
                src={logo2}
              ></img>
            </IonCol>

            <IonCol size="12">
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" className="ion-text-center">
                  <span>
                    Si ya has realizado la prueba <b>CheckSeller</b> y deseas
                    ver nuevamente tus resultados obtenidos, ingresa aquí tu
                    número de documento (sin puntos, espacios o comas).
                  </span>
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="8"
                  className="ion-justify-content-center"
                >
                  <IonRow className="ion-justify-content-center">
                    <IonCol size="12" sizeMd="6">
                      <IonInput
                        className="input"
                        placeholder="Número de identificación"
                        value={value}
                        onIonInput={(e: any) => setValue(e.target.value)}
                        type="text"
                      ></IonInput>
                    </IonCol>
                    <IonCol size="12" sizeMd="6">
                      <IonButton color="btncheck" onClick={checkRepoInput}>
                        Consultar
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal
          isOpen={isOpenModal}
          onDidDismiss={() => setIsOpenModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton color="danger" onClick={() => setIsOpenModal(false)}>
                  X
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="modalRepos ion-padding">
            <IonGrid className="gridModal">
              <IonRow>
                <IonCol>
                  <h1 className="h1Top">Tus repositorios:</h1>
                </IonCol>
              </IonRow>
              <IonRow className="repos ion-justify-content-center">
                <IonCol size="11" className="ion-padding ion-text-center">
                  De las siguientes pruebas que has realizado, selecciona la que
                  desesa ver:
                </IonCol>
                <IonCol size="11">
                  {dataSearch.map((data, index2) => (
                    <IonRow key={data + index2} className=" ion-padding">
                      <IonCol size="10">
                        <p>
                          Prueba Checkseller presentada en: <b>{data.fecha}</b>
                        </p>
                      </IonCol>
                      <IonCol size="2" className="ion-text-center">
                        <IonIcon
                          className="iconMore"
                          color="btnCheck"
                          icon={enterOutline}
                          onClick={() => goToResult(index2)}
                        ></IonIcon>
                      </IonCol>
                    </IonRow>
                  ))}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Verificando..."}
        />
      </IonContent>
    </IonPage>
  );
};

export default Home;
