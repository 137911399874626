import { IonContent, IonPage } from '@ionic/react';

import Seccion1 from '../components/items/Seccion1';
import Seccion2 from '../components/items/Seccion2';
import { useHistory, useParams } from 'react-router';

import './Items.css';
import 'animate.css';
import { useEffect } from 'react';
import { decrypt } from '../App';

const Items: React.FC = () => {

  const history = useHistory();
  let { noItem } = useParams<{ noItem: string; }>();
  let respuestas: any[] = Array(50).fill(0);

  if (sessionStorage.getItem('respuestas')) {
    respuestas = JSON.parse(decrypt(sessionStorage.getItem('respuestas')!));
  }

  useEffect(() => {
    if(!sessionStorage.getItem('infoSapiolab')!){
      window.location.replace("https://checkseller.web.app/")
    }
    
    if (noItem == undefined || parseInt(noItem) > 50) {
      noItem = '1'
      history.replace("/items/1")
    }
  });

  return (
    <IonPage>
      <IonContent fullscreen className='contentItems'>
        {(parseInt(noItem) >= 1 && parseInt(noItem) < 41) &&
          <Seccion1 item={noItem} respuestas={respuestas} />
        }

        {(parseInt(noItem) > 40 && parseInt(noItem) < 51) &&
          <Seccion2 item={noItem} respuestas={respuestas} />
        }
      </IonContent>
    </IonPage >
  );
};

export default Items;
