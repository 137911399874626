import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow } from '@ionic/react';
import './Seccion1.css';
import { useHistory } from 'react-router';
import { encrypt } from '../../App';

interface ContainerProps {
  item: string;
  respuestas: Array<any>
}

const Seccion1: React.FC<ContainerProps> = ({ item, respuestas }) => {

  const history = useHistory();
  const preguntas: any = {
    '1': "Al momento de crear el plan de trabajo, tomo en cuenta el tiempo para prospectar en mi calendario.",
    '2': "En el embudo de ventas tengo claro la cantidad de oportunidades a generar para llegar al resultado esperado.",
    '3': "Identifico todos los puntos importantes que califican a una persona como prospecto.",
    '4': "Comprendo cuál es el futuro esperado de mi cliente y quienes son sus competidores.",
    '5': "Conozco las herramientas eficaces en las que se pueden encontrar los prospectos para el producto o servicio que vendo.",
    '6': "En cada uno de los empleos que he tenido, me enfoco en construir una lista de prospectos amplia.",
    '7': "Evaluo la posibilidad de ejecutar la visita comercial en un escenario diferente a la ubicación del cliente.",
    '8': "Verifico de manera técnica que tengo todos los elementos necesarios para que el encuentro de ventas sea exitoso.",
    '9': "Hago una investigación detallada del cliente al cual voy a visitar.",
    '10': "Durante la ejecución de la visita de ventas, reviso si el discurso que estoy usando es efectivo o no.",
    '11': "Adapto el discurso de venta dependiendo el tipo de cliente al cuál abordo.",
    '12': "Al organizar las reuniones de ventas garantizo que estén todas las personas interesadas en la reunión.",
    '13': "Preparo una apertura positiva para iniciar la conversación con el cliente.",
    '14': "Al momento de brindar asesoría a un cliente, dedico la mayoría del tiempo a escuchar al cliente, sin dejar de lado el tiempo para hacer preguntas.",
    '15': "Me enfoco en contestar los interrogantes de mi cliente con el objetivo de inducir al cierre de la venta.",
    '16': "Identifico el lenguaje del comprador para poder establecer una comunicación efectiva.",
    '17': "Me siento seguro de que la solución que voy a proponer cubre la necesidad identificada.",
    '18': "Identifico cuáles son los puntos de dolor de las personas a las cuales estoy presentando.",
    '19': "Me preocupo constantemente en posicionarme en la mente del cliente como la persona que le va a solucionar su problema.",
    '20': "Ajusto los beneficios que otorgo al cliente con base en las necesidades que pude identificar en el primer acercamiento.",
    '21': "Detecto facilmente las objeciones presentadas por el cliente al momento de presentarle mi propuesta.",
    '22': "Uso diferentes técnicas para contrarrestrar las verdaderas objeciones de los clientes.",
    '23': "Empleo métodos para identificar las objeciones de mayor peso que están imposibilitando el cierre de la venta.",
    '24': "Me aseguro de ir respondiendo una a una todas las objeciones que el cliente me va presentando.",
    '25': "Logro identificar la manera en la que debo sortear una objeción que realmente mi propuesta no cubre al momento de la presentación.",
    '26': "Durante la conversación con el cliente, diferencio entre los diferentes tipos de objeciones para saber cómo responder adecuadamente.",
    '27': "Cuando no cierro una venta identifico las razones por las cuales no sucedió.",
    '28': "Identifico cuál es el momento adecuado para realizar el cierre de la venta.",
    '29': "Voy preparado con diferentes alternativas para negociar con el cliente.",
    '30': "Tengo la habilidad de cambiarle el rumbo a la negociación cuando identifico que no se va a dar el cierre de la venta.",
    '31': "Después del cierre de la negociación, rectifico con el cliente aspectos como las condiciones financieras del negocio, tiempos de entrega, servicio postventa, garantía, entre otras.",
    '32': "Envío al cliente un mensaje en e-mail o Whatsapp con lo pactado en la negociación.",
    '33': "Después del cierre de la venta, evalúo si brindé la mejor experiencia al cliente.",
    '34': "Realizo un análisis de la visita de ventas con el fin de identificar fortalezas y oportunidades de mejora.",
    '35': "Si se presentaron objeciones en el proceso de venta, identifico con claridad cuáles fueron los motivos que pudieron provocarlo aunque el cierre se haya dado.",
    '36': "Después de concretarse el cierre de la venta, estoy tranquilo porque el equipo de servicio implementará lo vendido.",
    '37': "Utilizo alguna metodología que garantice hacer un barrido por el 100% de mi cartera de clientes.",
    '38': "Si tengo la certeza que hay un cliente al que no le puedo vender, está en mi plan de trabajo volverlo a contactar.",
    '39': "Reviso con qué periodicidad luego del cierre de la venta vuelvo a contactar al cliente.",
    '40': "En la interacción con el cliente, me preocupo más por el servicio así el cierre de la venta tarde mas de lo esperado.",

  }

  let options = [1, 2, 3, 4, 5];
  let descp = ['Nunca', 'Casi nunca', 'A veces', 'Casi siempre', 'Siempre'];
  let select: number = 0

  const getValue = (value: number) => {
    select = value
    // console.log(select)
  }

  const continuar = () => {
    if (select == 0) {
      alert('Selecciona una opción para continuar')
    } else {
      if (item == '40') {
        respuestas[parseInt(item) - 1] = select
        let respuestasEnc = encrypt(JSON.stringify(respuestas))
        sessionStorage.setItem('respuestas', respuestasEnc)
        select = 0
        history.push('/situacion/1')
      } 
      else if (item == '14') {
        respuestas[parseInt(item) - 1] = select
        let respuestasEnc = encrypt(JSON.stringify(respuestas))
        sessionStorage.setItem('respuestas', respuestasEnc)
        select = 0
        history.push('/validacion/1')
      }
      else if (item == '29') {
        respuestas[parseInt(item) - 1] = select
        let respuestasEnc = encrypt(JSON.stringify(respuestas))
        sessionStorage.setItem('respuestas', respuestasEnc)
        select = 0
        history.push('/validacion/2')
      }
      else {
        respuestas[parseInt(item) - 1] = select
        let respuestasEnc = encrypt(JSON.stringify(respuestas))
        sessionStorage.setItem('respuestas', respuestasEnc)
        select = 0
        history.push('/items/' + (parseInt(item) + 1))
      }
    }
  }

  return (
    <IonGrid className='gridSeccion1'>
      <IonRow className='rowSeccion1 ion-justify-content-center'>
        <IonCol size='10' className='ion-justify-content-center ion-align-self-center ion-text-center ion-margin'>
          <b className='ion-padding-vertical'>{preguntas[item]}</b>
        </IonCol>

        <IonCol size='11' className='ion-align-self-center ion-padding'>
          <IonRadioGroup value="" onIonChange={e => getValue(e.detail.value)}>
            <IonRow>

              {
                options.map((opt, index) =>
                  <IonCol key={opt} sizeMd='2.4' size='12' className='opc ion-no-padding'>
                    <IonRow className='opcRow'>
                      <IonCol size='12' className='opcCol ion-no-padding'>
                        <IonItem className="itemOpt">
                          <IonLabel className='labelOpt ion-text-center'>
                            <h2 className='headerOpt'>{options[index]}</h2>
                            <h2 className='bodyOpt'>{descp[index]}</h2>
                            <IonRadio id={String(opt)} className='radioSelect' value={opt}></IonRadio>
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                )
              }

            </IonRow>
          </IonRadioGroup>
        </IonCol>

        <IonCol size='12' className='ion-no-padding ion-text-center'>
          <IonButton color='btncheck' onClick={continuar}>Siguiente</IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Seccion1;
