import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import Registro from "./pages/Registro";
import Intro from "./pages/Intro";
import TyC from "./pages/TyC";
import Instruccion from "./pages/Instruccion";
import Items from "./pages/Items";
import Situacion from "./pages/Situacion";
import Resultados from "./pages/Resultados";
import Validacion from "./pages/Validacion";
import * as CryptoJS from "crypto-js";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "swiper/css";

setupIonicReact();

const crypto = CryptoJS;
const ENC = "bf3c199c2470cb477d907b1e0917c17b";

export const encrypt = (text: string) => {
  let encrypted = crypto.AES.encrypt(text, ENC).toString();
  return encrypted;
};

export const decrypt = (text: string) => {
  let decrypted = crypto.AES.decrypt(text, ENC).toString(CryptoJS.enc.Utf8);
  return decrypted;
};

const search = window.location.search.substring(1);

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="*">
          <Redirect
            to={{
              pathname: "/home",
              search: search,
            }}
          />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/intro">
          <Intro />
        </Route>
        <Route path="/registro">
          <Registro />
        </Route>
        <Route path="/instruccion">
          <Instruccion />
        </Route>
        <Route path="/tyc">
          <TyC />
        </Route>
        <Route path="/items">
          <Items />
        </Route>
        <Route path="/items/:noItem" exact={true}>
          <Items />
        </Route>
        <Route path="/situacion">
          <Situacion />
        </Route>
        <Route path="/situacion/:index" exact={true}>
          <Situacion />
        </Route>
        <Route path="/validacion">
          <Validacion />
        </Route>
        <Route path="/validacion/:index" exact={true}>
          <Validacion />
        </Route>
        <Route path="/resultados">
          <Resultados />
        </Route>
        <Route exact path="/">
          <Redirect
            to={{
              pathname: "/home",
              search: search,
            }}
          />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
