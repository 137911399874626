import { IonContent, IonPage } from '@ionic/react';
import { useHistory, useParams } from 'react-router';

import './Situacion.css';
import 'animate.css';
import { useEffect } from 'react';
import Problema from '../components/items/Problema';


const Items: React.FC = () => {

  const history = useHistory();
  let { index } = useParams<{ index: string; }>();

  useEffect(() => {
    if (index == undefined) {
      index = '1'
      history.replace("/situacion/1")
    }
  });

  return (
    <IonPage>
      <IonContent fullscreen className='contentSituacion'>
        <Problema index={index} />
      </IonContent>
    </IonPage >
  );
};

export default Items;
