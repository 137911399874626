import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonLabel,
  IonSelect,
  IonText,
  IonRadioGroup,
  IonRadio,
  IonCheckbox,
  IonSelectOption,
  IonButton,
  IonContent,
} from "@ionic/react";

import User from "../interfaces/user";

import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import './Registro.css';
import { encrypt } from "../App";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

let user: User = {
  nombre: '',
  edad: '',
  documento: '',
  correo: '',
  empresas: '',
  experiencia: '',
  estudios: '',
  comercio: '',
  genero: '',
  fecha: '',
  uid: '',
  validaciones: '',
  respuestas: '',
  resultados: {},
}

const Registro: React.FC = () => {

  const history = useHistory();

  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      nombre: '',
      edad: '',
      documento: '',
      correo: '',
      empresas: '',
      experiencia: '',
      estudios: '',
      comercio: '',
      genero: '',
      tyc: false,
    }
  });

  const onSubmit = (data: any) => {
    // console.log(data)
    user.nombre = data.nombre
    user.edad = data.edad
    user.documento = data.documento
    user.correo = data.correo
    user.empresas = data.empresas
    user.experiencia = data.experiencia
    user.estudios = data.estudios
    user.comercio = data.comercio
    user.genero = data.genero
    user.fecha = new Date().toISOString();
    sessionStorage.setItem('user', encrypt(JSON.stringify(user)))
    history.push('/instruccion')
  };

  const terminos = () => {
    const host: string = window.location.origin;
    window.open(host+'/tyc', '_blank'); 
    return false;
  }

  return (
    <IonPage>
      <IonContent fullscreen className='contentRegistro'>
        <form onSubmit={handleSubmit(onSubmit)} className="formRegistro">
          <IonGrid className="gridRegistro">
            <IonRow className="rowRegistro ion-justify-content-center">

              <IonCol size="12" sizeMd="12" sizeLg="6">

                <IonRow>
                  <IonCol size="12">
                    {/* === ION INPUT: NOMBRE === */}
                    <IonItem className="itemForm"ion-text-wrap >
                      <IonLabel className="ion-text-wrap">Nombre y apellidos:</IonLabel>
                      <IonInput
                        {...register('nombre', {
                          required: 'Este campo es requerido',
                          pattern: {
                            value: /^[A-Za-z]/i,
                            message: 'Dato invalido'
                          }
                        })}
                      />
                    </IonItem>

                    <ErrorMessage
                      errors={errors}
                      name="nombre"
                      as={<div style={{ color: 'red', fontSize: '16px' }} />}
                    />
                  </IonCol>

                  <IonCol size="12" sizeMd="4.5">
                    {/* === ION INPUT: EDAD === */}
                    <IonItem className="itemForm ion-text-wrap" counter={true} >
                      <IonLabel className="ion-text-wrap">Edad:</IonLabel>
                      <IonInput type="number" maxlength={2} minlength={2}
                        {...register('edad', {
                          required: 'Este campo es requerido',
                          minLength: {
                            value: 2,
                            message: 'Este campo debe tener mínimo 2 dígitos'
                          },
                          maxLength: {
                            value: 2,
                            message: 'Este campo debe tener máximo 2 dígitos'
                          }
                        })}
                      />
                    </IonItem>

                    <ErrorMessage
                      errors={errors}
                      name="edad"
                      as={<div style={{ color: 'red', fontSize: '16px' }} />}
                    />
                  </IonCol>

                  <IonCol size="12" sizeMd="7.5">
                    {/* === ION INPUT: DOCUMENTO === */}
                    <IonItem className="itemForm ion-text-wrap" counter={true}>
                      <IonLabel className="ion-text-wrap">N° documento:</IonLabel>
                      <IonInput type="number" maxlength={12} minlength={4}
                        {...register('documento', {
                          required: 'Este campo es requerido',
                          minLength: {
                            value: 4,
                            message: 'Este campo debe tener mínimo 4 dígitos'
                          },
                          maxLength: {
                            value: 12,
                            message: 'Este campo debe tener máximo 12 dígitos'
                          }
                        })}
                      />
                    </IonItem>

                    <ErrorMessage
                      errors={errors}
                      name="documento"
                      as={<div style={{ color: 'red', fontSize: '16px' }} />}
                    />
                  </IonCol>

                  <IonCol size="12">
                    {/* === ION INPUT: CORREO === */}
                    <IonItem className="itemForm ion-text-wrap" >
                      <IonLabel className="ion-text-wrap">Correo:</IonLabel>
                      <IonInput
                        {...register('correo', {
                          required: 'Este campo es requerido',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Correo invalido'
                          }, value: ''
                        })}
                      />
                    </IonItem>

                    <ErrorMessage
                      errors={errors}
                      name="correo"
                      as={<div style={{ color: 'red', fontSize: '16px' }} />}
                    />
                  </IonCol>

                </IonRow>

              </IonCol>

              <IonCol size="12" sizeMd="12" sizeLg="6">

                <IonRow>
                  <IonCol size="12">
                    {/* === ION SELECT === */}
                    <IonItem className="itemForm ion-text-wrap">
                      <IonLabel className="ion-text-wrap">Sector productivo de las empresas en las que ha trabajado</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonSelect
                            placeholder="Selecciona uno"
                            value={field.value}
                            onIonDismiss={e => { setValue('empresas', e.target.value) }}
                            // onIonChange={e => {setValue('empresas', e.detail.value)}}
                            multiple={true}
                          >
                            <IonSelectOption value="Agricultura pesca y silvicultura">Agricultura pesca y silvicultura</IonSelectOption>
                            <IonSelectOption value="Alimentacion">Alimentación</IonSelectOption>
                            <IonSelectOption value="Arquitectura, artes, diseno">Arquitectura, artes, diseño</IonSelectOption>
                            <IonSelectOption value="Construccion y extraccion">Construcción y extracción</IonSelectOption>
                            <IonSelectOption value="Educacion">Educación</IonSelectOption>
                            <IonSelectOption value="Entretenimiento y deportes">Entretenimiento y deportes</IonSelectOption>
                            <IonSelectOption value="Informatica y tecnologia">Informática y tecnología</IonSelectOption>
                            <IonSelectOption value="Ingenieria">Ingeniería</IonSelectOption>
                            <IonSelectOption value="Leyes">Leyes</IonSelectOption>
                            <IonSelectOption value="Limpieza, mantenimiento y reparacion">Limpieza, mantenimiento y reparación</IonSelectOption>
                            <IonSelectOption value="Matematica">Matemática</IonSelectOption>
                            <IonSelectOption value="Medios de comunicacion">Medios de comunicación</IonSelectOption>
                            <IonSelectOption value="Operaciones comerciales y financieras">Operaciones comerciales y financieras</IonSelectOption>
                            <IonSelectOption value="Salud">Salud</IonSelectOption>
                            <IonSelectOption value="Servicio social y comunitario">Servicio social y comunitario</IonSelectOption>
                            <IonSelectOption value="Transporte">Transporte</IonSelectOption>
                            <IonSelectOption value="Ventas y servicios">Ventas y servicios</IonSelectOption>

                          </IonSelect>
                        )}
                        control={control}
                        name="empresas"
                        rules={{ required: 'Este campo es requerido' }}
                      />
                    </IonItem>
                    <ErrorMessage
                      errors={errors}
                      name="experiencia"
                      as={<div style={{ color: 'red' }} />}
                    />
                  </IonCol>

                  <IonCol size="12">
                    {/* === ION SELECT === */}
                    <IonItem>
                      <IonLabel className="ion-text-wrap">Años de experiencia en el área de ventas</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonSelect
                            placeholder="Selecciona uno"
                            value={field.value}
                            onIonChange={e => setValue('experiencia', e.detail.value)}
                          >
                            <IonSelectOption value="Sin expeiencia">Sin expeiencia</IonSelectOption>
                            <IonSelectOption value="Menos de 1 ano">Menos de 1 año</IonSelectOption>
                            <IonSelectOption value="1">1</IonSelectOption>
                            <IonSelectOption value="2">2</IonSelectOption>
                            <IonSelectOption value="3">3</IonSelectOption>
                            <IonSelectOption value="4">4</IonSelectOption>
                            <IonSelectOption value="5">5</IonSelectOption>
                            <IonSelectOption value="6">6</IonSelectOption>
                            <IonSelectOption value="7">7</IonSelectOption>
                            <IonSelectOption value="8">8</IonSelectOption>
                            <IonSelectOption value="9">9</IonSelectOption>
                            <IonSelectOption value="10">10</IonSelectOption>
                            <IonSelectOption value="Mas de 10 anos">Más de 10 años</IonSelectOption>
                          </IonSelect>
                        )}
                        control={control}
                        name="experiencia"
                        rules={{ required: 'Este campo es requerido' }}
                      />
                    </IonItem>
                    <ErrorMessage
                      errors={errors}
                      name="experiencia"
                      as={<div style={{ color: 'red' }} />}
                    />
                  </IonCol>

                  <IonCol size="12">
                    {/* === ION SELECT === */}
                    <IonItem>
                      <IonLabel className="ion-text-wrap">Nivel máximo de estudios alcanzado</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonSelect
                            placeholder="Selecciona uno"
                            value={field.value}
                            onIonChange={e => setValue('estudios', e.detail.value)}
                          >
                            <IonSelectOption value="Primaria">Primaria</IonSelectOption>
                            <IonSelectOption value="Técnico">Técnico</IonSelectOption>
                            <IonSelectOption value="Tecnólogo">Tecnólogo</IonSelectOption>
                            <IonSelectOption value="Pregrado">Pregrado</IonSelectOption>
                            <IonSelectOption value="Posgrado">Posgrado</IonSelectOption>
                            <IonSelectOption value="Bachiller">Bachiller</IonSelectOption>
                            <IonSelectOption value="Cursos autónomos">Cursos autónomos</IonSelectOption>

                          </IonSelect>
                        )}
                        control={control}
                        name="estudios"
                        rules={{ required: 'Este campo es requerido' }}
                      />
                    </IonItem>
                    <ErrorMessage
                      errors={errors}
                      name="experiencia"
                      as={<div style={{ color: 'red' }} />}
                    />
                  </IonCol>

                  <IonCol size="12">
                    {/* === ION SELECT === */}
                    <IonItem>
                      <IonLabel className="ion-text-wrap">Actualmente eres vendedor:</IonLabel>
                      <Controller
                        render={({ field }) => (
                          <IonSelect
                            placeholder="Selecciona uno"
                            value={field.value}
                            onIonChange={e => setValue('comercio', e.detail.value)}
                          >
                            <IonSelectOption value="No soy vendedor">No soy vendedor</IonSelectOption>
                            <IonSelectOption value="B2B">B2B (vendes de empresa a empresa)</IonSelectOption>
                            <IonSelectOption value="B2C">B2C (vendes de empresa a consumidor final)</IonSelectOption>
                            <IonSelectOption value="B2G">B2G (vendes de empresa a gobierno)</IonSelectOption>
                            <IonSelectOption value="Otro">Otro</IonSelectOption>
                          </IonSelect>
                        )}
                        control={control}
                        name="comercio"
                        rules={{ required: 'Este campo es requerido' }}
                      />
                    </IonItem>
                    <ErrorMessage
                      errors={errors}
                      name="experiencia"
                      as={<div style={{ color: 'red' }} />}
                    />
                  </IonCol>
                </IonRow>

              </IonCol>

              <IonCol size="12" sizeMd="8">
                <IonRow>
                  <IonCol size="12">
                    {/* === ION RADIO === */}
                    <IonItem>
                      <IonText className="textRegistro">
                        <div style={{ padding: 8, paddingLeft: 0, }}>
                          Género
                        </div>
                        <div>
                          <IonRadioGroup className="radioGroup"
                            style={{ display: 'flex', width: '100%' }}
                            {...register('genero', { required: true })}
                            defaultValue={getValues('genero')}
                            onIonChange={e => setValue('genero', e.detail.value)}
                          >
                            <IonItem
                              lines="none"
                              style={{
                                flexGrow: 2
                              }}
                            >
                              <IonLabel className="ion-text-wrap" position="fixed">F</IonLabel>
                              <IonRadio className="radioRegistro" slot="end" value="Femenino" />
                            </IonItem>

                            <IonItem style={{ flexGrow: 2 }} lines="none">
                              <IonLabel className="ion-text-wrap" position="fixed">M</IonLabel>
                              <IonRadio className="radioRegistro" slot="end" value="Masculino" />
                            </IonItem>
                            <IonItem style={{ flexGrow: 2 }} lines="none">
                              <IonLabel className="ion-text-wrap" position="fixed">Otro</IonLabel>
                              <IonRadio className="radioRegistro" slot="end" value="Otro" />
                            </IonItem>
                          </IonRadioGroup>
                        </div>
                      </IonText>
                    </IonItem>
                    {errors.genero && (
                      <span className="error-msg">Este campo es requerido</span>
                    )}
                  </IonCol>
                </IonRow>
              </IonCol>

              <IonCol size="12">
                <IonRow className="ion-justify-content-center">
                  <IonCol size="auto">

                    {/* === ION CHECKBOX === */}
                    <IonItem className="itemForm ion-text-wrap ion-text-center">
                      <IonLabel className="ion-text-wrap ion-padding-horizontal">Acepto <a href="#" onClick={terminos}>Términos y Condiciones
                      </a> de la prueba</IonLabel>
                      <Controller
                        name="tyc"
                        control={control}
                        render={({ field }) => {
                          return (
                            <IonCheckbox
                              slot="start"
                              checked={field.value}
                              onIonChange={e => {
                                setValue('tyc', e.detail.checked);
                              }}
                              {...register('tyc', {
                                required: 'Este campo es requerido',
                                // pattern: {
                                //   value: /^[0-9]{10}/i,
                                //   message: 'Edad invalida',
                                // }
                              })}
                            />
                          );
                        }}
                      />
                    </IonItem>
                    <ErrorMessage
                      errors={errors}
                      name="tyc"
                      as={<div style={{ color: 'red', fontSize: '16px' }} />}
                    />
                  </IonCol>

                  <IonCol size="12" className="ion-text-center">
                    <div>
                      <IonButton shape={"round"} color="btncheck" type="submit" className="ion-margin">Continuar</IonButton>
                    </div>
                  </IonCol>
                </IonRow>

              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Registro;
