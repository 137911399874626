import { IonContent, IonPage } from '@ionic/react';
import { useHistory, useParams } from 'react-router';

import './Validacion.css';
import 'animate.css';
import { useEffect } from 'react';
import Validacion from '../components/items/Validacion';


const Items: React.FC = () => {

  const history = useHistory();
  let { index } = useParams<{ index: string; }>();
  let validaciones: any[] = Array(3).fill(0);

  if (sessionStorage.getItem('validaciones')) {
    validaciones = JSON.parse(sessionStorage.getItem('validaciones')!);
  }

  useEffect(() => {
    if (index == undefined) {
      index = '1'
      history.replace("/validacion/1")
    }
  });

  return (
    <IonPage>
      <IonContent fullscreen className='contentValidacion'>
        <Validacion item={index} validaciones={validaciones} />
      </IonContent>
    </IonPage >
  );
};

export default Items;
