// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAu73yDOH6SoIwR_HcwjKmTksFYQGpeYnQ",
  authDomain: "sapiolab-48252.firebaseapp.com",
  databaseURL: "https://sapiolab-48252-default-rtdb.firebaseio.com",
  projectId: "sapiolab-48252",
  storageBucket: "sapiolab-48252.appspot.com",
  messagingSenderId: "497175897227",
  appId: "1:497175897227:web:b11645b1447417c4bbe08e",
  measurementId: "G-2BMYETGE83"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();