import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';
import { useHistory } from 'react-router';
import './Problema.css';
import { useEffect } from 'react';

import img1 from '../../assets/imgs/prueba/CHSE1_1.png'
import img2 from '../../assets/imgs/prueba/CHSE2_1.png'
import img3 from '../../assets/imgs/prueba/CHSE3_1.png'
import img4 from '../../assets/imgs/prueba/CHSE4_1.png'

interface ContainerProps {
  index: string;
}

const Problema: React.FC<ContainerProps> = ({ index }) => {
  const imgIntro = [img1, img2, img3, img4]
  const history = useHistory();

  useEffect(()=>{
    if(parseInt(index) < 1 || parseInt(index) >4){
      history.push('/situacion/1')
    }
  }, [])

  const situaciones: any = {
    1: 'Usted es vendedor de soluciones de software en una compañía de EE. UU., la filosofía de dicha empresa es trabajar en equipo para lograr las metas del mes, por ello, la gerencia comercial, estructura unos beneficios por el cumplimiento de la meta grupal y sistema de comisión individual. En el mes de febrero su equipo no logró cumplir la meta, pero usted rebasó sus ventas, se convirtió en el empleado del mes y recibió un beneficio económico. Aun así, usted reconoce que en febrero dejó de lado otras labores planeadas, que influyen en los resultados del mes siguiente, ya que se enfocó solo en cerrar ventas.',

    2: 'Usted es vendedor de automóviles de una multinacional alemana, llega al concesionario un comprador quien es dueño de varios terrenos del sector, él le expresa que quiere regalarle un auto a su mamá pero solo su esposa conoce los gustos de ella, al mostrarle las referencias disponibles, él indica que todas salen de su presupuesto, a lo que usted responde que esos son los precios que establece la compañía por lo que lastimosamente solo podría hacer una cosa al respecto, ofrecerle un crédito que es aprobado el mismo día, al llevarlo al área financiera estos hacen su respectiva recepción de documentos y le indican que en un máximo de 3 días recibirá respuesta, el cliente agarra su maleta y dice que prefiere ir a otro concesionario porque no confía en dicha marca.',

    3: 'Lucas es un vendedor que visita comercios que distribuyen bebidas alcohólicas, en esta ocasión identificó que se están haciendo las adecuaciones de una bodega para inaugurar una distribuidora mayorista de licores, tras varios intentos, logró conseguir el número del jefe de compras y concretar una cita. Al llegar la fecha, Lucas verifica desde temprano que lleve todos los elementos necesarios para la presentación del producto, al encontrarse con el jefe de compras pregunta si están todos los interesados en la reunión y ante su respuesta afirmativa procede a comenzar, el jefe de compras estrecha su mano y le pregunta si ha visto la noticia de la alza del dólar a lo que Lucas responde que ha sido un tema que ha afectado a todos inclusive su empresa porque suben los precios del producto pero que esperan que se estabilice en dos semanas, el jefe de compras le indica que acaba de recordar una reunión que tenía programada que si podían reprogramar esa para dentro de dos semanas.',

    4: 'Carlos logra una cita con el gerente de tecnología de una universidad, lo cual es muy positivo porque la compañía es muy fuerte en el sector educativo, cuando inicia la reunión detecta que es una institución que está casada con una marca con la que la compañía no tiene ningún convenio, y es política de esta continuar igual. Sin embargo, le permiten a Carlos continuar con su presentación, pero a la mitad de la reunión y dado la objeción presentada el gerente le pide, luego de unos minutos, cortar la reunión porque se le presento algo urgente.'
  }

  const continuar = () => {
    if (index == '1') {
      history.push('/items/41')
    } else if (index == '2') {
      history.push('/items/44')
    } else if (index == '3') {
      history.push('/items/47')
    } else if (index == '4') {
      history.push('/items/49')
    }
  }

  return (
    <IonGrid className='gridProblema'>
      <IonRow className='rowProblema ion-justify-content-center'>
        <IonCol size='12' sizeMd='8' className='ion-justify-content-center ion-align-self-center ion-padding ion-text-justify'>
          <b>Vamos a evaluar tus conocimientos y experiencia en todas las fases de la venta, lee atentamente la situación que se presenta a continuación para responder las preguntas que saldrán después de que presione continuar:</b>
          <br></br><br></br>
          <span>
            {situaciones[parseInt(index)]}
          </span>
        </IonCol>
        <IonCol size='12' sizeMd='4' className='ion-text-center'>
          <img className='imgProblema' src={imgIntro[parseInt(index) - 1]}></img>
          <b>Situación {index}</b>
        </IonCol>
        <IonCol size='12' className='ion-no-padding ion-text-center'>
          <IonButton color='btncheck' onClick={continuar}>Continuar</IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default Problema;
